@charset "UTF-8";

/* 
  tailwind base 
*/
@tailwind base;

/* 
  tailwind components 
*/
@tailwind components;

/*
  tailwind utilities 
*/
@tailwind utilities;

/*
  font
*/
@import "./scss/font";

/*
  component
*/
@import "./scss/component";
